<template>
  <view class="physicalGood">
    <image class="goods-image" :src="goodItem[imgUrl]" mode="widthFix" />
    <img :src="defaultImg" v-if="!goodItem.stock" class="defaultImgs" alt="">
    <view class="item-box">
      <h4 class="item-title">{{ goodItem[goodName] }}</h4>
      <Price :price="goodItem[price]" ></Price>
    </view>
  </view>
</template>
<script>
import Price from "@/components/price";
const baseUrl = import.meta.env.SHOPRO_BASE_IMG_URL;
export default {
  data() {
    return {
      baseUrl: baseUrl,
      current: 0,
      defaultImg:`${baseUrl}/static/images/author/replenish_icon.png`
    };
  },

  components: {
    Price,
  },
  props: {
    goodItem: {
      type: Object,
      default: {},
    },
    imgUrl:{
      type:String,
      default:'picUrl'
    },
    goodName:{
      type:String,
      default:'name'
    },
    price:{
      type:String,
      default:'price'
    },
  },
  methods: {
    click_item(e) {
      this.swiperDotIndex = e;
    },
  },
};
</script>
<style lang="scss" scoped>
.physicalGood {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  .defaultImgs{
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .goods-image {
    width: 100%;
    height: 168px;
    border-radius: 10rpx;
    overflow: hidden;
  }
  .item-box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    // height: calc( 100% - 168px );
  }
  .item-title {
    width: 100%;
    text-align: left;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 8rpx 0;
  }
}
</style>
