<template>
  <view class="price">
    <view v-if="price || price===0" class="moneyLeft" :style="`color:${moneyColor};font-size:${fontSize}px`">
      <img v-if="priceImg"
        src="https://h5.fushangyunfu.com/public/yunfuHelp/beanIcon.png" :style="`width:${ImgWidth}px;height:${ImgWidth}px;`" />
      <view class="money" v-if="price"> 
        <span>{{ fen2yuan(price).split('.')[0] }}.</span>
        <span style="font-size: 28rpx;">{{ fen2yuan(price).split('.')[1]  }}</span>
      </view>
      <view class="money" v-else> 0 </view>
      <view v-if="range" class="info"> 起</view>
    </view>
  </view>
</template>
<script>
import { fen2yuan } from "@/sheep/hooks/useGoods";
export default {
  name: "price",
  props: {
    priceImg: {
      type: Boolean,
      default: true
    },
    price: {
      type: [String, Number],
      default: ""
    },
    moneyColor: {
      type: String,
      default: '#fb5436'
    },
    fontSize: {
      type: [String, Number],
      default: '16'
    },
    ImgWidth: {
      type: [String, Number],
      default: '18'
    },
    range: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    
  },
  data() {
    return {};
  },
  methods: {
    fen2yuan
  },
  mounted() { }
};
</script>
<style lang="scss" scoped>
.price {
   height: 40rpx ;
   display: flex;
   align-items: center;
  .moneyLeft {
    display: flex;
    align-items: center;
    font-size: 36rpx;
    font-weight: 600;
    img {
      margin-right: 8rpx;
      position: relative;
      top: -2px;
    }
    .money{
      line-height: 38rpx;
      font-size: 36rpx;
    }
    .info{
      font-size: 12px;
      margin-left: 6rpx;
    }
  }

}
</style>
